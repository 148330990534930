import { FilteredNetSuiteTable } from '@/components/FilteredNetSuiteTable';
import { Button } from '@/components/ui/button';
import { useAuth } from '@/contexts/AuthContext';
import { Navigation } from '@/components/layout/Navigation';
import { Routes, Route, Navigate } from 'react-router-dom';
import { LocationSelector } from '@/components/LocationSelector';
import { useLocationContext } from '@/contexts/LocationContext';
import { LogOutIcon } from 'lucide-react';
import { MobileMenu } from '@/components/layout/MobileMenu';
import { UserManagement } from '@/components/UserManagement';

export function Dashboard() {
  const { logout } = useAuth();
  const { selectedLocation } = useLocationContext();

  const getBackgroundColor = () => {
    if (!selectedLocation) return 'bg-gray-50';

    const [_, state] = selectedLocation.split(', ');
    switch (state) {
      case 'TX':
        return 'bg-[#5DB0EB]';
      case 'WA':
        return 'bg-[#5BCE8B]';
      case 'USA':
        return 'bg-[#BFA34E]';
      default:
        return 'bg-white';
    }
  };

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-gray-50 to-gray-100">
      <header className={`fixed top-0 left-0 right-0 border-b shadow-md z-50 ${getBackgroundColor()}`}>
        <div className='max-w-[1400px] mx-auto px-4 md:px-6'>
          <div className='flex justify-between items-center h-16'>
            <div className='flex items-center gap-2'>
              <img src='/android-chrome-512x512.png' alt='Templar Logo' className='w-8 h-8' />
              <div>
                <h1 className='text-xl font-bold text-gray-900'>Production Panel</h1>
                <p className='text-sm text-gray-500 hidden sm:block'>Templar Screens Manufacturing</p>
              </div>
            </div>
            <div className='hidden md:flex items-center gap-4'>
              <LocationSelector />
              <Button variant="outline" onClick={logout} className="gap-2">
                <LogOutIcon className='w-4 h-4' />
                <span>Logout</span>
              </Button>
            </div>
            <div className="md:hidden">
              <MobileMenu />
            </div>
          </div>
          <Navigation />
        </div>
      </header>

      <main className='pt-32 px-4 md:px-6 pb-24 md:pb-8 max-w-[1400px] mx-auto'>
        <Routes>
          <Route index element={<FilteredNetSuiteTable filter='all' />} />
          <Route path='fabric' element={<FilteredNetSuiteTable filter='fabric' />} />
          <Route path='kitting' element={<FilteredNetSuiteTable filter='kitting' />} />
          <Route path='metal' element={<FilteredNetSuiteTable filter='metal' />} />
          <Route path='assembly' element={<FilteredNetSuiteTable filter='assembly' />} />
          <Route path='users' element={<UserManagement />} />
          <Route path='*' element={<Navigate to='' replace />} />
        </Routes>
      </main>
    </div>
  );
}
