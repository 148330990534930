import { Sheet, SheetContent, SheetTrigger, SheetClose } from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { LocationSelector } from "@/components/LocationSelector";
import { useAuth } from "@/contexts/AuthContext";
import { MoreVertical, LogOut, Users } from "lucide-react";
import { NavLink } from "react-router-dom";
import { UserRole } from "@/lib/types";

export function MobileMenu() {
  const { logout, currentUser } = useAuth();
  
  const showUsersLink = currentUser?.roles.some(role => 
    role === UserRole.ADMIN || 
    role === UserRole.USER_MANAGEMENT
  );

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon" className="md:hidden">
          <MoreVertical className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent>
        <div className="h-full flex flex-col">
          <div className="space-y-4 flex-1">
            <h2 className="text-lg font-semibold tracking-tight">Location</h2>
            <LocationSelector />
            
            {showUsersLink && (
              <>
                <h2 className="text-lg font-semibold tracking-tight pt-4">Management</h2>
                <SheetClose asChild>
                  <NavLink 
                    to="/dashboard/users"
                    className="flex items-center text-sm"
                  >
                    <Users className="mr-2 h-4 w-4" />
                    Users
                  </NavLink>
                </SheetClose>
              </>
            )}
          </div>
          <SheetClose asChild>
            <Button 
              onClick={logout} 
              className="w-full justify-start"
            >
              <LogOut className="mr-2 h-4 w-4" />
              Logout
            </Button>
          </SheetClose>
        </div>
      </SheetContent>
    </Sheet>
  );
}