import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createSocket, closeSocket } from '@/lib/socket';
import { User } from '@/lib/types';
import { jwtDecode } from "jwt-decode";

interface AuthContextType {
  isAuthenticated: boolean;
  token: string | null;
  currentUser: User | null;
  login: (token: string) => void;
  logout: () => void;
  setUser: (user: User | null) => void;
}

const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  token: null,
  currentUser: null,
  login: () => {},
  logout: () => {},
  setUser: () => {},
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState<string | null>(localStorage.getItem('token'));
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode<{ user: User; exp: number }>(token);
        
        // Check if token is expired
        if (decoded.exp * 1000 < Date.now()) {
          logout();
          return;
        }

        setUser(decoded.user);
        
        // Set up expiration timeout
        const timeUntilExpiry = decoded.exp * 1000 - Date.now();
        const logoutTimer = setTimeout(logout, timeUntilExpiry);
        
        return () => clearTimeout(logoutTimer);
      } catch (error) {
        logout();
      }
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      const socket = createSocket(token);
      
      socket.on('userUpdate', (data: { user: User, token?: string }) => {
        if (user?._id === data.user._id && data.token) {
          localStorage.setItem('token', data.token);
          setToken(data.token);
          setUser(data.user);
        } else {
          setUser(prevUser => prevUser?._id === data.user._id ? data.user : prevUser);
        }
      });

      return () => {
        socket.off('userUpdate');
      };
    }
  }, [token]);

  const login = (newToken: string) => {
    localStorage.setItem('token', newToken);
    setToken(newToken);
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    setUser(null);
    closeSocket();
    navigate('/login');
  };

  const contextValue = useMemo(
    () => ({
      isAuthenticated: !!token,
      token,
      currentUser: user,
      login,
      logout,
      setUser,
    }),
    [token, user],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);