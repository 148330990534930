import { NavLink } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Home, Package, Scissors, Box, PenTool, Users } from 'lucide-react';
import { useAuth } from '@/contexts/AuthContext';
import { UserRole } from '@/lib/types';

const allNavItems = [
  { path: '/dashboard', label: 'All Orders', icon: Home, roles: [UserRole.MANAGER, UserRole.ADMIN, UserRole.USER_MANAGEMENT] },
  { path: '/dashboard/kitting', label: 'Kitting', icon: Package, roles: [UserRole.KITTING] },
  { path: '/dashboard/fabric', label: 'Fabric', icon: Scissors, roles: [UserRole.FABRIC] },
  { path: '/dashboard/metal', label: 'Metal', icon: PenTool, roles: [UserRole.POWDER] },
  { path: '/dashboard/assembly', label: 'Assembly', icon: Box, roles: [UserRole.ASSEMBLY] },
  { path: '/dashboard/users', label: 'Users', icon: Users, roles: [UserRole.USER_MANAGEMENT, UserRole.ADMIN] },
];

export function Navigation() {
  const location = useLocation();
  const { currentUser } = useAuth();

  const filteredNavItems = allNavItems.filter(item => {
    if (item.roles.length === 0) return true;
    if (!currentUser?.roles) return false;
    return currentUser.roles.some(role => 
      role === UserRole.ADMIN || 
      role === UserRole.MANAGER ||
      item.roles.includes(role)
    );
  });

  useEffect(() => {
    const currentTab = location.pathname.split('/').pop() || 'all';
    Cookies.set('lastActiveTab', currentTab, { expires: 30 });
  }, [location.pathname]);

  return (
    <nav>
      {/* Desktop Navigation */}
      <div className="hidden md:flex space-x-1">
        {filteredNavItems.map(({ path, label, icon: Icon }) => (
          <NavLink
            key={path}
            to={path}
            className={({ isActive }) =>
              `px-4 py-3 text-sm font-medium rounded-t-md transition-colors flex items-center gap-2
              ${isActive 
                ? 'bg-white text-primary border-t border-x border-b-white border-gray-200 relative -mb-px' 
                : 'text-gray-600 hover:text-gray-900 hover:bg-gray-50'
              }`
            }
            end
          >
            <Icon className="w-4 h-4" />
            {label}
          </NavLink>
        ))}
      </div>

      {/* Mobile Navigation */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t md:hidden">
        <div className="flex justify-around items-center h-16">
          {filteredNavItems
            .filter(item => item.path !== '/dashboard/users')
            .map(({ path, label, icon: Icon }) => (
            <NavLink
              key={path}
              to={path}
              className={({ isActive }) =>
                `flex flex-col items-center justify-center w-full h-full
                ${isActive 
                  ? 'text-primary' 
                  : 'text-gray-500'
                }`
              }
              end
            >
              <Icon className="w-5 h-5" />
              <span className="text-xs mt-1">{label}</span>
            </NavLink>
          ))}
        </div>
      </div>
    </nav>
  );
} 