// This will be replaced during build time with the actual Git commit hash
declare const __COMMIT_HASH__: string;

export const APP_VERSION = '1.2.0';
export const COMMIT_HASH = typeof __COMMIT_HASH__ !== 'undefined' 
  ? __COMMIT_HASH__.slice(0, 6) 
  : 'dev';

export const isVersionNewer = (version1: string, version2: string): boolean => {
  const v1Parts = version1.split('.').map(Number);
  const v2Parts = version2.split('.').map(Number);
  
  for (let i = 0; i < 3; i++) {
    if (v1Parts[i] > v2Parts[i]) return true;
    if (v1Parts[i] < v2Parts[i]) return false;
  }
  return false;
}; 