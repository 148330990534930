import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Toaster } from '@/components/ui/toaster';
import { AuthProvider } from '@/contexts/AuthContext';
import { LoginForm } from '@/components/auth/LoginForm';
import { RegisterForm } from '@/components/auth/RegisterForm';
import { ProtectedRoute } from '@/components/auth/ProtectedRoute';
import { Dashboard } from '@/components/Dashboard';
import { PageContainer } from '@/components/layout/PageContainer';
import { LocationProvider } from '@/contexts/LocationContext';

function App() {
  return (
    <Router>
      <AuthProvider>
        <LocationProvider>
          <Routes>
            <Route path="/login" element={<PageContainer><LoginForm /></PageContainer>} />
            <Route path="/register" element={<PageContainer><RegisterForm /></PageContainer>} />
            <Route path="/dashboard/*" element={
              <ProtectedRoute>
                <PageContainer>
                  <Dashboard />
                </PageContainer>
              </ProtectedRoute>
            } />
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
          </Routes>
          <Toaster />
        </LocationProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
