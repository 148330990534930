import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect, useState } from "react";
import { useLocationContext as useLocation } from "@/contexts/LocationContext";

export function LocationSelector() {
  const { locations, selectedLocation, setSelectedLocation } = useLocation();
  const [stateIcons, setStateIcons] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const loadStateIcons = async () => {
      const icons: { [key: string]: string } = {};
      
      for (const location of locations) {
        try {
          // Use URL constructor to ensure proper path resolution
          const iconUrl = new URL(`/states/${location.state}.svg`, window.location.origin).href;
          // Verify the SVG exists
          const response = await fetch(iconUrl);
          if (response.ok) {
            icons[location.state] = iconUrl;
          } else {
            console.warn(`SVG not found for ${location.state}`);
          }
        } catch (error) {
          console.error(`Failed to load SVG for ${location.state}:`, error);
        }
      }
      setStateIcons(icons);
    };

    loadStateIcons();
  }, [locations]);

  return (
    <Select value={selectedLocation || ''} onValueChange={setSelectedLocation}>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="Select location" />
      </SelectTrigger>
      <SelectContent>
        {locations.map((location) => (
          <SelectItem
            key={`${location.city}-${location.state}`}
            value={`${location.city}, ${location.state}`}
          >
            <div className="flex items-center gap-2">
              {stateIcons[location.state] && (
                <img 
                  src={stateIcons[location.state]} 
                  alt={location.state}
                  className="w-4 h-4" 
                />
              )}
              {`${location.city}, ${location.state}`}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
} 