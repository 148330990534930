import { useEffect, useState } from 'react';
import { useToast } from '@/components/ui/use-toast';
import { useSocket } from '@/lib/socket';
import { Checkbox } from '@/components/ui/checkbox';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { ConfirmActionDialog } from './ConfirmActionDialog';
import { useLocationContext } from '@/contexts/LocationContext';
import { Button } from '@/components/ui/button';
import { APP_VERSION, COMMIT_HASH } from '@/lib/version';

interface NetSuiteData {
  [key: string]: any;
  id: string;
  orderID: string;
  customer: string;
  date: string;
  location: string;
  schedulePriority: number;
  scheduleFrame: string;
  scheduleFabric: string;
  fabricType: string;
  frameColorVaries: boolean;
  frameColor: string;
  buildoutFrameColor: string;
  hemBarFrameColor: string;
  housingFrameColor: string;
  retentionFrameColor: string;
  kittingComplete: boolean;
  fabricComplete: boolean;
  metalComplete: boolean;
  finalAssemblyComplete: boolean;
}

const columnMappings: { [key: string]: string } = {
  orderID: 'ID',
  customer: 'Customer',
  date: 'Date',
  fabricDetails: 'Fabric Details',
  frameColorDetails: 'Powder Details',
  kittingComplete: 'Kitting',
  fabricComplete: 'Fabric',
  metalComplete: 'Metal',
  finalAssemblyComplete: 'Final Assembly',
  actions: 'Actions',
};

const sortColumns = ['date', 'schedulePriority', 'scheduleFrame', 'scheduleFabric'];

interface NetSuiteTableProps {
  filterFn: (data: any[]) => any[];
  onRowClick?: (row: NetSuiteData) => void;
  showCheckmarks?: boolean;
  currentFilter: 'all' | 'kitting' | 'fabric' | 'metal' | 'assembly';
}

export function NetSuiteTable({
  filterFn,
  onRowClick,
  showCheckmarks,
  currentFilter
}: NetSuiteTableProps) {
  const socket = useSocket();
  const [data, setData] = useState<NetSuiteData[]>([]);
  const [rawData, setRawData] = useState<NetSuiteData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const [selectedRow, setSelectedRow] = useState<NetSuiteData | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const { selectedLocation } = useLocationContext();

  // Request initial data on mount
  useEffect(() => {
    if (socket) {
      socket.emit('requestNetSuiteData');
      
      // Add timeout for loading state
      const loadingTimeout = setTimeout(() => {
        if (isLoading) {
          setIsLoading(false);
          toast({
            title: 'Error',
            description: 'Failed to load data. Please try refreshing the page.',
            variant: 'destructive',
          });
        }
      }, 10000); // 10 second timeout

      return () => clearTimeout(loadingTimeout);
    }
  }, [socket, isLoading]);

  // Handle raw data updates from socket
  useEffect(() => {
    if (socket) {
      socket.on('netsuiteData', (newData) => {
        const sortedData = [...newData].sort((a, b) => {
          // First sort by priority
          if (a.schedulePriority > b.schedulePriority) return -1;
          if (a.schedulePriority < b.schedulePriority) return 1;

          // Then sort by date
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          if (dateA < dateB) return -1;
          if (dateA > dateB) return 1;

          // Then sort by other columns
          for (const column of sortColumns) {
            if (column === 'schedulePriority') continue;
            if (a[column] < b[column]) return -1;
            if (a[column] > b[column]) return 1;
          }
          return 0;
        });
        setRawData(sortedData);
        setIsLoading(false);
      });

      socket.on('netsuiteError', (error) => {
        toast({
          title: 'Error',
          description: error.message,
          variant: 'destructive',
        });
        setIsLoading(false);
      });

      socket.on('connect', () => {
        toast({
          title: 'Connected',
          description: 'Connected to server successfully',
        });
      });

      socket.on('disconnect', () => {
        toast({
          title: 'Disconnected',
          description: 'Lost connection to server',
          variant: 'destructive',
        });
      });

      return () => {
        socket.off('netsuiteData');
        socket.off('netsuiteError');
        socket.off('connect');
        socket.off('disconnect');
      };
    }
  }, [socket, toast]);

  // Apply filter whenever raw data or filter function changes
  useEffect(() => {
    setData(filterFn ? filterFn(rawData) : rawData);
  }, [rawData, filterFn]);

  const handleRowClick = (row: NetSuiteData) => {
    if (onRowClick) {
      setSelectedRow(row);
      setIsDialogOpen(true);
    }
  };

  const handleConfirm = async () => {
    if (selectedRow && onRowClick) {
      setIsUpdating(true);
      try {
        await onRowClick(selectedRow);
        setIsDialogOpen(false);
        setSelectedRow(null);
      } catch (error) {
        // Error handling is done in FilteredNetSuiteTable
      } finally {
        setIsUpdating(false);
      }
    }
  };

  // Filter data based on selected location
  const filteredData =
    selectedLocation && selectedLocation !== 'All, USA'
      ? data.filter((item) => {
          const lastSpaceIndex = item.location.lastIndexOf(' ');
          const city = item.location.slice(0, lastSpaceIndex).trim();
          const state = item.location.slice(lastSpaceIndex + 1).trim();
          return `${city}, ${state}` === selectedLocation;
        })
      : data;

  const getRelevantColumns = () => {
    const baseColumns = ['orderID', 'customer', 'date'];
    
    switch (currentFilter) {
      case 'all':
        return [...baseColumns, 'fabricDetails', 'frameColorDetails', 'kittingComplete', 'fabricComplete', 'metalComplete', 'finalAssemblyComplete'];
      case 'kitting':
        return [...baseColumns, 'actions'];
      case 'fabric':
        return [...baseColumns, 'fabricDetails', 'actions'];
      case 'metal':
        return [...baseColumns, 'frameColorDetails', 'actions'];
      case 'assembly':
        return [...baseColumns, 'actions'];
      default:
        return baseColumns;
    }
  };

  const displayColumns = getRelevantColumns().filter((column) => {
    // On mobile, only show essential columns
    if (typeof window !== 'undefined' && window.innerWidth < 768) {
      return ['orderID', 'customer', 'actions'].includes(column);
    }
    return true;
  });

  const renderCell = (value: any, column: string) => {
    if (typeof value === 'boolean') {
      return showCheckmarks ? <Checkbox checked={value} disabled /> : null;
    }
    if (column === 'date') {
      return new Date(value).toLocaleDateString();
    }
    if (typeof value === 'string' && value.includes('<br />')) {
      return <div dangerouslySetInnerHTML={{ __html: value }} />;
    }
    return value;
  };

  if (isLoading) {
    return <div className='text-center text-lg'>Loading...</div>;
  }

  if (!data.length) {
    return <div className='text-center text-lg'>All items are up to date!</div>;
  }

  return (
    <div className='w-full overflow-auto'>
      {/* Desktop Table */}
      <div className='hidden md:block'>
        <Table>
          <TableCaption>Version {APP_VERSION} ({COMMIT_HASH})</TableCaption>
          <TableHeader>
            <TableRow className="bg-gray-50/80">
              {displayColumns.map((column) => (
                <TableHead key={column} className="whitespace-nowrap font-semibold text-gray-700">
                  {columnMappings[column]}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {filteredData.map((row) => (
              <TableRow 
                key={row.id} 
                className="hover:bg-gray-50/80 transition-colors cursor-default"
              >
                {displayColumns.map((column) => (
                  <TableCell key={column} className='whitespace-nowrap'>
                    {column === 'actions' && onRowClick ? (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRowClick(row);
                        }}
                        size='sm'
                        variant='outline'
                      >
                        Mark Complete
                      </Button>
                    ) : (
                      renderCell(row[column], column)
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      {/* Mobile Card Layout */}
      <div className='grid grid-cols-1 gap-4 md:hidden'>
        {filteredData.map((row) => (
          <div key={row.id} className='bg-white rounded-lg border border-gray-200 shadow-sm p-4 hover:border-gray-300 transition-colors'>
            <div className='space-y-3'>
              <div className='flex justify-between items-start'>
                <div>
                  <div className='font-semibold text-gray-900'>#{row.orderID}</div>
                  <div className='text-sm text-gray-600'>{row.customer}</div>
                </div>
                {onRowClick && (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRowClick(row);
                    }}
                    size='sm'
                    variant='outline'
                    className="shrink-0"
                  >
                    Mark Complete
                  </Button>
                )}
              </div>

              {/* Show details based on current filter */}
              {(currentFilter === 'fabric' || currentFilter === 'all') && row.fabricDetails && (
                <div className='text-sm'>
                  <div className='font-medium mb-1'>Fabric Details:</div>
                  <div className='text-muted-foreground'>{row.fabricDetails}</div>
                </div>
              )}
              {(currentFilter === 'metal' || currentFilter === 'all') && row.frameColorDetails && (
                <div className='text-sm'>
                  <div className='font-medium mb-1'>Powder Details:</div>
                  <div className='text-muted-foreground' dangerouslySetInnerHTML={{ __html: row.frameColorDetails }} />
                </div>
              )}

              {showCheckmarks && (
                <div className='grid grid-cols-2 gap-2 pt-2'>
                  {[
                    'kittingComplete',
                    'fabricComplete',
                    'metalComplete',
                    'finalAssemblyComplete',
                  ].map((status) => (
                    <div key={status} className='flex items-center gap-2'>
                      <Checkbox checked={row[status]} disabled />
                      <span className='text-sm'>{columnMappings[status]}</span>
                    </div>
                  ))}
                </div>
              )}

              <div className='text-sm text-muted-foreground'>
                {new Date(row.date).toLocaleDateString()}
              </div>
            </div>
          </div>
        ))}
      </div>
      <ConfirmActionDialog
        isOpen={isDialogOpen}
        onClose={() => !isUpdating && setIsDialogOpen(false)}
        onConfirm={handleConfirm}
        title='Confirm Status Update'
        description='Are you sure you want to update the status of this item?'
        isLoading={isUpdating}
      />
    </div>
  );
}
