import { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { Checkbox } from '@/components/ui/checkbox';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/contexts/AuthContext';
import axios from 'axios';
import { User, UserRole } from '@/lib/types';

const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:5001/api';

const availableRoles = {
  stations: [
    { id: 'kitting', label: 'Kitting', description: 'Access to kitting station tasks' },
    { id: 'fabric', label: 'Fabric', description: 'Access to fabric station tasks' },
    { id: 'powder', label: 'Powder', description: 'Access to powder coating tasks' },
    { id: 'assembly', label: 'Assembly', description: 'Access to assembly station tasks' },
  ],
  management: [
    { id: 'manager', label: 'Manager', description: 'View and manage all orders' },
    { id: 'admin', label: 'Admin', description: 'Full system access' },
    { id: 'user_management', label: 'User Management', description: 'Manage users and permissions' },
  ]
};

export function UserManagement() {
  const [users, setUsers] = useState<User[]>([]);
  const { token, currentUser, login } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_URL}/users`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUsers(response.data);
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to fetch users',
        variant: 'destructive',
      });
    }
  };

  const handleRoleChange = async (userId: string, role: string, checked: boolean) => {
    const user = users.find(u => u._id === userId);
    if (!user) return;

    const newRoles = checked
      ? [...user.roles, role]
      : user.roles.filter(r => r !== role);

    // Update UI immediately
    setUsers(prevUsers => prevUsers.map(u => 
      u._id === userId ? { ...u, roles: newRoles as UserRole[] } : u
    ));

    try {
      const response = await axios.put(
        `${API_URL}/users/roles`,
        { userId, roles: newRoles },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Update with server response in case of any differences
      setUsers(prevUsers => prevUsers.map(u => 
        u._id === userId ? response.data.user : u
      ));

      if (userId === currentUser?._id && response.data.token) {
        login(response.data.token);
        setUsers(prevUsers => prevUsers.map(u => 
          u._id === userId ? response.data.user : u
        ));
      }

      toast({
        title: 'Success',
        description: 'User roles updated successfully',
      });
    } catch (error) {
      // Revert changes on error
      setUsers(prevUsers => prevUsers.map(u => 
        u._id === userId ? user : u
      ));
      
      toast({
        title: 'Error',
        description: 'Failed to update user roles',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold tracking-tight">Users</h1>
          <p className="text-muted-foreground">Manage user roles and permissions</p>
        </div>
      </div>

      <div className="grid gap-6">
        {users.map(user => (
          <Card key={user._id} className="overflow-hidden">
            <CardHeader className="bg-muted/50">
              <div className="flex items-center justify-between">
                <CardTitle className="text-lg font-medium">
                  {user.email}
                </CardTitle>
                {currentUser?._id === user._id && (
                  <span className="text-xs bg-primary/10 text-primary px-2 py-1 rounded-full">
                    Current User
                  </span>
                )}
              </div>
            </CardHeader>
            <CardContent className="pt-6">
              <div className="grid gap-8">
                <div>
                  <div className="flex items-center gap-2 mb-4">
                    <h3 className="text-sm font-semibold">Production Stations</h3>
                    <div className="h-px flex-1 bg-border" />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {availableRoles.stations.map(role => (
                      <div key={role.id} className="flex items-start space-x-3 p-3 rounded-lg border bg-card">
                        <Checkbox
                          id={`${user._id}-${role.id}`}
                          checked={user.roles.includes(role.id as UserRole)}
                          onCheckedChange={(checked) => 
                            handleRoleChange(user._id, role.id as UserRole, checked as boolean)
                          }
                          className="mt-0.5"
                        />
                        <div className="space-y-1">
                          <label 
                            htmlFor={`${user._id}-${role.id}`}
                            className="text-sm font-medium leading-none cursor-pointer"
                          >
                            {role.label}
                          </label>
                          <p className="text-xs text-muted-foreground">{role.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div>
                  <div className="flex items-center gap-2 mb-4">
                    <h3 className="text-sm font-semibold">Administrative Access</h3>
                    <div className="h-px flex-1 bg-border" />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {availableRoles.management.map(role => (
                      <div key={role.id} className="flex items-start space-x-3 p-3 rounded-lg border bg-card">
                        <Checkbox
                          id={`${user._id}-${role.id}`}
                          checked={user.roles.includes(role.id as UserRole)}
                          onCheckedChange={(checked) => 
                            handleRoleChange(user._id, role.id as UserRole, checked as boolean)
                          }
                          className="mt-0.5"
                        />
                        <div className="space-y-1">
                          <label 
                            htmlFor={`${user._id}-${role.id}`}
                            className="text-sm font-medium leading-none cursor-pointer"
                          >
                            {role.label}
                          </label>
                          <p className="text-xs text-muted-foreground">{role.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
} 