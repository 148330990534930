import { NetSuiteTable } from './NetSuiteTable';
import axios from 'axios';
import { useToast } from '@/components/ui/use-toast';
import { useAuth } from '@/contexts/AuthContext';

const API_URL = (import.meta.env.VITE_API_URL || 'http://localhost:5001').replace(/\/api$/, '');

interface FilteredNetSuiteTableProps {
  filter: 'all' | 'kitting' | 'fabric' | 'metal' | 'assembly';
}

export function FilteredNetSuiteTable({ filter }: FilteredNetSuiteTableProps) {
  const { toast } = useToast();
  const { token } = useAuth();
  
  const handleRowClick = async (row: any) => {
    if (filter === 'all') return;

    const updateField = {
      kitting: 'kittingComplete',
      fabric: 'fabricComplete',
      metal: 'metalComplete',
      assembly: 'finalAssemblyComplete'
    }[filter];

    try {
      await axios.put(`${API_URL}/api/netsuite/data`, {
        id: row.id,
        kittingComplete: updateField === 'kittingComplete' ? true : row.kittingComplete,
        fabricComplete: updateField === 'fabricComplete' ? true : row.fabricComplete,
        metalComplete: updateField === 'metalComplete' ? true : row.metalComplete,
        finalAssemblyComplete: updateField === 'finalAssemblyComplete' ? true : row.finalAssemblyComplete
      }, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      toast({
        title: 'Success',
        description: `Updated ${filter} status`,
      });
    } catch (error) {
      console.error('Update error:', error);
      toast({
        title: 'Error',
        description: error instanceof Error ? error.message : 'Failed to update status',
        variant: 'destructive',
      });
      throw error;
    }
  };

  const filterData = (data: any[]) => {
    const addDetails = (row: any) => ({
      ...row,
      fabricDetails: row.fabricType ? `${row.fabricType}` : '-',
      frameColorDetails: row.frameColorVaries
        ? `<b>Housing:</b> ${row.housingFrameColor}<br />` +
          `<b>Buildout:</b> ${row.buildoutFrameColor}<br />` +
          `<b>Hem Bar:</b> ${row.hemBarFrameColor}<br />` +
          `<b>Retention:</b> ${row.retentionFrameColor}`
        : `${row.frameColor || '-'}`
    });

    switch (filter) {
      case 'kitting':
        return data.filter((row) => !row.kittingComplete);
      case 'fabric':
        return data.filter((row) => !row.fabricComplete && row.kittingComplete)
          .map(row => addDetails(row));
      case 'metal':
        return data.filter((row) => row.fabricComplete && row.kittingComplete && !row.metalComplete)
          .map(row => addDetails(row));
      case 'assembly':
        return data.filter(
          (row) => row.fabricComplete && row.kittingComplete && row.metalComplete && !row.finalAssemblyComplete
        );
      default:
        return data.map(row => addDetails(row));
    }
  };

  return <NetSuiteTable 
    filterFn={filterData} 
    onRowClick={filter === 'all' ? undefined : handleRowClick} 
    showCheckmarks={filter === 'all'} 
    currentFilter={filter}
  />;
} 