export enum UserRole {
  KITTING = 'kitting',
  FABRIC = 'fabric',
  POWDER = 'powder',
  ASSEMBLY = 'assembly',
  MANAGER = 'manager',
  ADMIN = 'admin',
  USER_MANAGEMENT = 'user_management'
}

export interface User {
  _id: string;
  email: string;
  roles: UserRole[];
} 