import { io, Socket } from 'socket.io-client';
import { useAuth } from '@/contexts/AuthContext';
import { useEffect, useState } from 'react';

const BASE_URL = import.meta.env.VITE_API_URL?.replace('/api', '') || 'http://localhost:5001';

let socket: Socket | null = null;
let currentToken: string | null = null;

export const createSocket = (token: string | null) => {
  if (socket && currentToken === token) {
    return socket;
  }

  if (socket) {
    socket.disconnect();
  }

  currentToken = token;
  socket = io(BASE_URL, {
    path: '/socket.io/',
    transports: ['websocket', 'polling'],
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    autoConnect: true,
    forceNew: false,
    auth: {
      token
    }
  });

  socket.on('connect_error', (error) => {
    if (error.message !== 'Invalid token' && error.message !== 'Authentication required') {
      console.error('Socket connection error:', error);
    }
  });

  return socket;
};

export const getSocket = () => socket;

export const closeSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
    currentToken = null;
  }
};

// Helper hook for components to safely use socket
export function useSocket() {
  const { token, logout } = useAuth();
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (!token) return;

    const newSocket = io(BASE_URL, {
      auth: { token },
      reconnectionAttempts: 3,
      reconnectionDelay: 1000,
    });

    newSocket.on('connect_error', (error) => {
      if (error.message === 'Invalid token') {
        logout();
      }
    });

    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [token, logout]);

  return socket;
} 