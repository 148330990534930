import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useNavigate, useLocation as useRouterLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { useSocket } from '@/lib/socket';

interface Location {
  city: string;
  state: string;
}

interface LocationContextType {
  locations: Location[];
  selectedLocation: string | null;
  setSelectedLocation: (location: string) => void;
}

const LocationContext = createContext<LocationContextType | undefined>(undefined);

export function LocationProvider({ children }: { children: ReactNode }) {
  const socket = useSocket();
  
  const [locations, setLocations] = useState<Location[]>([
    { city: "All", state: "USA" }
  ]);
  
  const cookieLocation = Cookies.get('selectedLocation');
  const [selectedLocation, setSelectedLocation] = useState<string | null>(cookieLocation || null);
  const navigate = useNavigate();
  const location = useRouterLocation();

  useEffect(() => {
    if (!cookieLocation) {
      const path = location.pathname;
      const matches = path.match(/\/([a-z]{2})\/([a-z-]+)\/dashboard/);
      if (matches) {
        const [, state, city] = matches;
        const formattedCity = city.split('-').map(word => 
          word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
        const formattedState = state.toUpperCase();
        setSelectedLocation(`${formattedCity}, ${formattedState}`);
        Cookies.set('selectedLocation', `${formattedCity}, ${formattedState}`, { expires: 30 });
      } else if (path.startsWith('/dashboard')) {
        setSelectedLocation('All, USA');
        Cookies.set('selectedLocation', 'All, USA', { expires: 30 });
      }
    }
  }, [location.pathname, cookieLocation]);

  const handleLocationChange = (newLocation: string) => {
    setSelectedLocation(newLocation);
    Cookies.set('selectedLocation', newLocation, { expires: 30 });
    
    const currentPath = location.pathname.split('/').pop() || '';
    const basePath = currentPath === 'dashboard' ? '' : `/${currentPath}`;
    
    navigate(`dashboard${basePath}`);
  };

  // Handle socket connection and data fetching
  useEffect(() => {
    if (!socket) return;

    const handleNetSuiteData = (data: any[]) => {
      const uniqueLocations = new Set<string>();
      data.forEach(item => {
        if (item.location) {
          uniqueLocations.add(item.location);
        }
      });

      const locationArray = Array.from(uniqueLocations).map(loc => {
        const lastSpaceIndex = loc.lastIndexOf(' ');
        return { 
          city: loc.slice(0, lastSpaceIndex).trim(), 
          state: loc.slice(lastSpaceIndex + 1).trim() 
        };
      });

      setLocations([{ city: "All", state: "USA" }, ...locationArray]);
    };

    // Set up socket event handlers
    socket.on('netsuiteData', handleNetSuiteData);
    
    // Request initial data immediately when socket is ready
    const requestData = () => {
      if (socket.connected) {
        socket.emit('requestNetSuiteData');
      }
    };

    // Request data when socket connects
    socket.on('connect', requestData);
    
    // Initial request if socket is already connected
    if (socket.connected) {
      requestData();
    }

    return () => {
      socket.off('netsuiteData', handleNetSuiteData);
      socket.off('connect', requestData);
    };
  }, [socket]);

  return (
    <LocationContext.Provider value={{ 
      locations, 
      selectedLocation, 
      setSelectedLocation: handleLocationChange 
    }}>
      {children}
    </LocationContext.Provider>
  );
}

export function useLocationContext() {
  const context = useContext(LocationContext);
  if (context === undefined) {
    throw new Error('useLocationContext must be used within a LocationProvider');
  }
  return context;
} 